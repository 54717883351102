import adminRoutes from "./routes";
import React, { Suspense, useState, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "./App.scss";
import "./assets/icons/style.css";
import { UserContext, RoleContext } from "./hooks/UserContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
const spinner = [
    <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" />
    </div>,
];
export default function App() {
    const routes = [...adminRoutes];
    let accessToken = undefined;

    if (sessionStorage.getItem("user_data")) {
        accessToken = JSON.parse(sessionStorage.getItem("user_data")).accessToken;
    }

    const [user, setUser] = useState(accessToken);
    const userInitVal = useMemo(() => ({ user, setUser }), [user, setUser]);

    const [Role, setRole] = useState(sessionStorage.getItem("role"));
    const RoleInitVal = useMemo(() => ({ Role, setRole }), [Role, setRole]);

    return (
        <BrowserRouter>
            <Suspense fallback={spinner}>
                <UserContext.Provider value={userInitVal}>
                    <RoleContext.Provider value={RoleInitVal}>
                        <Routes>
                            {routes.map(({ path, component: C }, idx) => {
                                return <Route key={idx} path={path} element={<C />} />;
                            })}
                        </Routes>
                    </RoleContext.Provider>
                </UserContext.Provider>
                <ToastContainer />
            </Suspense>
        </BrowserRouter>
    );
}