import { lazy } from 'react';

const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

// Initial Routes
const Login = lazy(() => lazyRetry(() => import("../Views/Login"), "Login"));
const ControlPanel = lazy(() => lazyRetry(() => import("../Views/ControlPanel"), "ControlPanel"));
const DashBoard = lazy(() => lazyRetry(() => import("../Views/DashBoard"), "DashBoard"));
const Client = lazy(() => lazyRetry(() => import("../Views/Client"), "Client"));
const WhiteListIp = lazy(() => lazyRetry(() => import("../Views/WhiteListIp"), "WhiteListIp"));
const ServicePage = lazy(() => lazyRetry(() => import("../Views/ControlPanel/ServicePage"), "ServicePage"));
const ViewPlan = lazy(() => lazyRetry(() => import("../Views/Setting/View"), "View"));
const MasterCred = lazy(() => lazyRetry(() => import("../Views/Master"), "MasterCred"));
const LambdaCred = lazy(() => lazyRetry(() => import("../Views/Lambda"), "LambdaCred"));
const DBCred = lazy(() => lazyRetry(() => import("../Views/DBCred"), "DBCred"));
const ManageClient = lazy(() => lazyRetry(() => import("../Views/Client/Manage"), "ManageClient"));
const Setting = lazy(() => lazyRetry(() => import("../Views/Setting"), "Setting"));
const Services = lazy(() => lazyRetry(() => import("../Views/Setting/Services"), "Services"));
const APiUsage = lazy(() => lazyRetry(() => import("../Views/UsageLogs/Reports/index"), "APiUsage"));
const APiUsageV2 = lazy(() => lazyRetry(() => import("../Views/UsageLogs/Reports/apiusageV2"), "APiUsageV2"));
const UsageLogs = lazy(() => lazyRetry(() => import("../Views/UsageLogs/index"), "UsageLogs"));
const UsageLogsV2 = lazy(() => lazyRetry(() => import("../Views/UsageLogs/useLogsV2"), "UsageLogs"));
const manageService = lazy(() => lazyRetry(() => import("../Views/DashBoard/manageService"), "manageService"));
const OnscreenUsage = lazy(() => lazyRetry(() => import("../Views/OnscreenUsage"), "OnscreenUsage"));
const TDSRate = lazy(() => lazyRetry(() => import("../Views/TDSRate"), "TDSRate"));
const services_list = lazy(() => lazyRetry(() => import("../Views/ServicesList/ServicesList.jsx"), "services_list"));
const session_data = lazy(() => lazyRetry(() => import("../Views/Session/sessionDetails"), "session_data"));

// const EmailTemplate = lazy(() => lazyRetry(() => import("../Views/EmailTemplate"), "EmailTemplate"));

const websitesRoutes = [
    // Initial Routes
    { path: `/`, exact: true, name: "Login", component: Login },
    { path: `/controlpanel`, exact: true, name: "Control Panel", component: ControlPanel },
    { path: `/dashboard`, exact: true, name: "Dashboard", component: DashBoard },
    { path: `/client`, exact: true, name: "Client", component: Client },
    { path: `/whitelistip`, exact: true, name: "White List Ip", component: WhiteListIp },
    { path: `/master-cred`, exact: true, name: "Master Credentials", component: MasterCred },
    { path: `/lambda-cred`, exact: true, name: "Lambda Credentials", component: LambdaCred },
    { path: `/db-cred`, exact: true, name: "DB Credentials", component: DBCred },
    { path: `/service/:service`, exact: true, name: "Service", component: ServicePage },
    { path: `/viewplan`, exact: true, name: "ViewPlan", component: ViewPlan },
    { path: `/manage`, exact: true, name: "Client Manage", component: ManageClient },
    { path: `/setting`, exact: true, name: "Control Panel", component: Setting },
    { path: `/services`, exact: true, name: "Services", component: Services },
    { path: `/apiusage`, exact: true, name: "Services", component: APiUsage },
    { path: `/apiusagev2`, exact: true, name: "Services", component: APiUsageV2 },
    { path: `/usage-logs/:date/:state`, exact: true, name: "Usage Logs", component: UsageLogs },
    { path: `/usage-logsV2/:date/:state`, exact: true, name: "Usage Logs V2", component: UsageLogsV2 },
    { path: `/manageService`, exact: true, name: "manageService", component: manageService },
    { path: `/onscreen-usage`, exact: true, name: "OnscreenUsage", component: OnscreenUsage },
    { path: `/tds-rate`, exact: true, name: "TDSRate", component: TDSRate },
    { path: `/services_list`, exact: true, name: "services_list", component: services_list },
    { path: `/session_data`, exact: true, name: "session_data", component: session_data },
    // { path: `/email-template`, exact: true, name: "EmailTemplate", component: EmailTemplate },
];

export default websitesRoutes;